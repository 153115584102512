var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-2" },
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "sm" } },
        [
          _c(
            "b-form-group",
            { attrs: { "label-cols-md": "2", label: _vm.$t("MAIL_SERVER") } },
            [
              _c("b-form-select", {
                attrs: { options: _vm.providerOptions },
                model: {
                  value: _vm.UpdateOrganisationEmailDTO.email_provider,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.UpdateOrganisationEmailDTO,
                      "email_provider",
                      $$v
                    )
                  },
                  expression: "UpdateOrganisationEmailDTO.email_provider",
                },
              }),
            ],
            1
          ),
          _vm.UpdateOrganisationEmailDTO.email_provider != "SEFOS"
            ? _c(
                "div",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-cols-md": "2",
                        label: _vm.$t("EMAILSETTINGS.PROTOCOL_TITLE"),
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          name: "edit_functionbox_email_mode",
                          options: _vm.email_mode,
                        },
                        model: {
                          value: _vm.UpdateOrganisationEmailDTO.email_mode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.UpdateOrganisationEmailDTO,
                              "email_mode",
                              $$v
                            )
                          },
                          expression: "UpdateOrganisationEmailDTO.email_mode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.change_from
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-md": "2",
                            label: _vm.$t("EMAILSETTINGS.FROM"),
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { trim: "", type: "text", name: "name" },
                            model: {
                              value: _vm.UpdateOrganisationEmailDTO.email_from,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.UpdateOrganisationEmailDTO,
                                  "email_from",
                                  $$v
                                )
                              },
                              expression:
                                "UpdateOrganisationEmailDTO.email_from",
                            },
                          }),
                          _c("b-form-text", [
                            _vm._v(_vm._s(_vm.$t("EMAILSETTINGS.HELP.FROM"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.UpdateOrganisationEmailDTO.email_mode == "SMTP",
                          expression:
                            "UpdateOrganisationEmailDTO.email_mode == 'SMTP'",
                        },
                      ],
                    },
                    [
                      _vm.change_from_name
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-cols-md": "2",
                                label: _vm.$t("EMAILSETTINGS.FROM_NAME"),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: { trim: "", type: "text", name: "name" },
                                model: {
                                  value:
                                    _vm.UpdateOrganisationEmailDTO
                                      .email_from_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.UpdateOrganisationEmailDTO,
                                      "email_from_name",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "UpdateOrganisationEmailDTO.email_from_name",
                                },
                              }),
                              _c("b-form-text", [
                                _vm._v(
                                  _vm._s(_vm.$t("EMAILSETTINGS.HELP.FROM_NAME"))
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-md": "2",
                            label: _vm.$t("EMAILSETTINGS.HOSTNAME"),
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              trim: "",
                              required: "",
                              type: "text",
                              name: "name",
                            },
                            model: {
                              value:
                                _vm.UpdateOrganisationEmailDTO.email_hostname,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.UpdateOrganisationEmailDTO,
                                  "email_hostname",
                                  $$v
                                )
                              },
                              expression:
                                "UpdateOrganisationEmailDTO.email_hostname",
                            },
                          }),
                          _c("b-form-text", [
                            _vm._v(
                              _vm._s(_vm.$t("EMAILSETTINGS.HELP.HOSTNAME"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-md": "2",
                            label: _vm.$t("EMAILSETTINGS.PORT"),
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { trim: "", type: "number", name: "name" },
                            model: {
                              value: _vm.UpdateOrganisationEmailDTO.email_port,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.UpdateOrganisationEmailDTO,
                                  "email_port",
                                  $$v
                                )
                              },
                              expression:
                                "UpdateOrganisationEmailDTO.email_port",
                            },
                          }),
                          _c("b-form-text", [
                            _vm._v(_vm._s(_vm.$t("EMAILSETTINGS.HELP.PORT"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-md": "2",
                            label: _vm.$t("EMAILSETTINGS.USERNAME"),
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { trim: "", type: "text", name: "name" },
                            model: {
                              value:
                                _vm.UpdateOrganisationEmailDTO.email_username,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.UpdateOrganisationEmailDTO,
                                  "email_username",
                                  $$v
                                )
                              },
                              expression:
                                "UpdateOrganisationEmailDTO.email_username",
                            },
                          }),
                          _c("b-form-text", [
                            _vm._v(
                              _vm._s(_vm.$t("EMAILSETTINGS.HELP.USERNAME"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-md": "2",
                            label: _vm.$t("EMAILSETTINGS.PASSWORD"),
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              trim: "",
                              type: "password",
                              name: "UpdateOrganisationEmailDTO.email_password",
                            },
                            model: {
                              value:
                                _vm.UpdateOrganisationEmailDTO.email_password,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.UpdateOrganisationEmailDTO,
                                  "email_password",
                                  $$v
                                )
                              },
                              expression:
                                "UpdateOrganisationEmailDTO.email_password",
                            },
                          }),
                          _c("b-form-text", [
                            _vm._v(
                              _vm._s(_vm.$t("EMAILSETTINGS.HELP.PASSWORD"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-md": "2",
                            label: _vm.$t("EMAILSETTINGS.SECURITY.TITLE"),
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: { options: _vm.email_securitys },
                            model: {
                              value:
                                _vm.UpdateOrganisationEmailDTO.email_security,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.UpdateOrganisationEmailDTO,
                                  "email_security",
                                  $$v
                                )
                              },
                              expression:
                                "UpdateOrganisationEmailDTO.email_security",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.UpdateOrganisationEmailDTO.email_mode ==
                            "GRAPH",
                          expression:
                            "UpdateOrganisationEmailDTO.email_mode == 'GRAPH'",
                        },
                      ],
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-md": "2",
                            label: _vm.$t("EMAILSETTINGS.TENANT"),
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { trim: "", type: "text", name: "name" },
                            model: {
                              value:
                                _vm.UpdateOrganisationEmailDTO
                                  .email_graph_tenant,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.UpdateOrganisationEmailDTO,
                                  "email_graph_tenant",
                                  $$v
                                )
                              },
                              expression:
                                "UpdateOrganisationEmailDTO.email_graph_tenant",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-md": "2",
                            label: _vm.$t("EMAILSETTINGS.CLIENTID"),
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { trim: "", type: "text", name: "name" },
                            model: {
                              value:
                                _vm.UpdateOrganisationEmailDTO
                                  .email_graph_client_id,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.UpdateOrganisationEmailDTO,
                                  "email_graph_client_id",
                                  $$v
                                )
                              },
                              expression:
                                "UpdateOrganisationEmailDTO.email_graph_client_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-md": "2",
                            label: _vm.$t("EMAILSETTINGS.CLIENTSECRET"),
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { trim: "", type: "password", name: "name" },
                            model: {
                              value:
                                _vm.UpdateOrganisationEmailDTO
                                  .email_graph_client_secret,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.UpdateOrganisationEmailDTO,
                                  "email_graph_client_secret",
                                  $$v
                                )
                              },
                              expression:
                                "UpdateOrganisationEmailDTO.email_graph_client_secret",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("hr", { staticClass: "p-0 m-0 mt-4 mb-2" }),
          _c(
            "b-button",
            {
              staticClass: "btn-fill",
              attrs: { variant: "primary" },
              on: { click: _vm.editBtn },
            },
            [_vm._v(_vm._s(_vm.$t("SAVE")))]
          ),
          _c(
            "b-button",
            {
              staticClass: "btn-fill",
              attrs: { variant: "secondary", disabled: !_vm.IsUpdated },
              on: { click: _vm.testBtn },
            },
            [_vm._v(_vm._s(_vm.$t("TEST")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }