<template>
  <section class="mt-2">
    <b-overlay :show="loading" rounded="sm">

      <b-form-group label-cols-md="2" :label="$t('MAIL_SERVER')">
        <b-form-select
          v-model="UpdateOrganisationEmailDTO.email_provider"
          :options="providerOptions"
        ></b-form-select>
      </b-form-group>

      
      <div v-if="UpdateOrganisationEmailDTO.email_provider != 'SEFOS'">
    
        <b-form-group label-cols-md="2" :label="$t('EMAILSETTINGS.PROTOCOL_TITLE')">
          <b-form-select  name="edit_functionbox_email_mode" v-model="UpdateOrganisationEmailDTO.email_mode" :options="email_mode">
          </b-form-select>
        </b-form-group>

        
        <b-form-group
          label-cols-md="2"
          :label="$t('EMAILSETTINGS.FROM')"
          v-if="change_from"
        >
          <b-form-input trim
            type="text"
            v-model="UpdateOrganisationEmailDTO.email_from"
            name="name"
          ></b-form-input>
          <b-form-text>{{ $t("EMAILSETTINGS.HELP.FROM") }}</b-form-text>
        </b-form-group>


        <div v-show="UpdateOrganisationEmailDTO.email_mode == 'SMTP'">
          <b-form-group
            label-cols-md="2"
            :label="$t('EMAILSETTINGS.FROM_NAME')"
            v-if="change_from_name"
          >
            <b-form-input trim
              type="text"
              v-model="UpdateOrganisationEmailDTO.email_from_name"
              name="name"
            ></b-form-input>
            <b-form-text>{{ $t("EMAILSETTINGS.HELP.FROM_NAME") }}</b-form-text>
          </b-form-group>

          <b-form-group
            label-cols-md="2"
            :label="$t('EMAILSETTINGS.HOSTNAME')"
          >
            <b-form-input trim
              required
              type="text"
              v-model="UpdateOrganisationEmailDTO.email_hostname"
              name="name"
            ></b-form-input>
            <b-form-text
              >{{ $t("EMAILSETTINGS.HELP.HOSTNAME") }}</b-form-text
            >
          </b-form-group>

          <b-form-group
            label-cols-md="2"
            :label="$t('EMAILSETTINGS.PORT')"
          >
            <b-form-input trim
              type="number"
              v-model="UpdateOrganisationEmailDTO.email_port"
              name="name"
            ></b-form-input>
            <b-form-text
              >{{ $t("EMAILSETTINGS.HELP.PORT") }}</b-form-text
            >
          </b-form-group>

          <b-form-group label-cols-md="2" :label="$t('EMAILSETTINGS.USERNAME')">
            <b-form-input trim
              type="text"
              v-model="UpdateOrganisationEmailDTO.email_username"
              name="name"
            ></b-form-input>
            <b-form-text
              >{{ $t("EMAILSETTINGS.HELP.USERNAME") }}</b-form-text
            >
          </b-form-group>

          <b-form-group label-cols-md="2" :label="$t('EMAILSETTINGS.PASSWORD')">
            <b-form-input trim
              type="password"
              v-model="UpdateOrganisationEmailDTO.email_password"
              name="UpdateOrganisationEmailDTO.email_password"
            ></b-form-input>

            <b-form-text
              >{{ $t("EMAILSETTINGS.HELP.PASSWORD") }}</b-form-text
            >
          </b-form-group>

          <b-form-group
            label-cols-md="2"
            :label="$t('EMAILSETTINGS.SECURITY.TITLE')"
          >
            <b-form-select
              v-model="UpdateOrganisationEmailDTO.email_security"
              :options="email_securitys"
            >
            </b-form-select>
          </b-form-group>
        </div>

        <div v-show="UpdateOrganisationEmailDTO.email_mode == 'GRAPH'">

          <b-form-group label-cols-md="2" :label="$t('EMAILSETTINGS.TENANT')">
            <b-form-input trim
              type="text"
              v-model="UpdateOrganisationEmailDTO.email_graph_tenant"
              name="name"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="2" :label="$t('EMAILSETTINGS.CLIENTID')">
            <b-form-input trim
              type="text"
              v-model="UpdateOrganisationEmailDTO.email_graph_client_id"
              name="name"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label-cols-md="2"
            :label="$t('EMAILSETTINGS.CLIENTSECRET')"
          >
            <b-form-input trim
              type="password"
              v-model="UpdateOrganisationEmailDTO.email_graph_client_secret"
              name="name"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <hr class="p-0 m-0 mt-4 mb-2" />

      <b-button variant="primary" class="btn-fill" @click="editBtn">{{
        $t("SAVE")
      }}</b-button>

      <b-button variant="secondary" :disabled="!IsUpdated" class="btn-fill" @click="testBtn">{{
        $t("TEST")
      }}</b-button>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["organisation"],
  data() {
    return {
      information: this.organisation,
      loading: false,
      change_password: false,
      change_from: true,
      change_from_name: true,
      email_securitys: [
        { value: 0, text: this.$t("EMAILSETTINGS.SECURITY.0") },
        { value: 2, text: this.$t("EMAILSETTINGS.SECURITY.2") },
        { value: 3, text: this.$t("EMAILSETTINGS.SECURITY.3") },
      ],
      providerOptions: [
        { value: "SEFOS", text: "SEFOS" },
        { value: "CUSTOM", text: this.$t('CUSTOM') },
      ],
      email_mode: [
        { value: "SMTP", text: this.$t("EMAILSETTINGS.SMTP") },
        { value: "GRAPH", text: this.$t("EMAILSETTINGS.MSGRAPH") },
      ],
      UpdateOrganisationEmailDTO: {
        organisation_id: this.organisation.id,
        email_provider: this.organisation.email_provider,
        email_from: this.organisation.email_from,
        email_from_name: this.organisation.email_from_name,
        email_hostname: this.organisation.email_hostname,
        email_port: this.organisation.email_port,
        email_username: this.organisation.email_username,
        email_password: this.organisation.email_password,
        email_security: this.organisation.email_security,
        change_password: this.change_password,
        email_mode: this.organisation.email_mode,
        email_graph_tenant: this.organisation.email_graph_tenant,
        email_graph_client_id: this.organisation.email_graph_client_id,
        email_graph_client_secret: this.organisation.email_graph_client_secret
      }
    };
  },
  methods: {
    editBtn: function() {
      let self = this;
      self.loading = true;
      this.UpdateOrganisationEmailDTO.email_port = parseInt(
        this.UpdateOrganisationEmailDTO.email_port
      );
      this.UpdateOrganisationEmailDTO.change_password = this.change_password;
      self.$http
        .post(
          self.user.hostname + "/organisation/email",
          self.UpdateOrganisationEmailDTO
        )
        .then(function() {
          self.$noty.info(self.$t("UPDATED"));
          self.organisation.email_provider =
            self.UpdateOrganisationEmailDTO.email_provider;
          self.organisation.email_from =
            self.UpdateOrganisationEmailDTO.email_from;
          self.organisation.email_from_name =
            self.UpdateOrganisationEmailDTO.email_from_name;
          self.organisation.email_hostname =
            self.UpdateOrganisationEmailDTO.email_hostname;
          self.organisation.email_port =
            self.UpdateOrganisationEmailDTO.email_port;
          self.organisation.email_username =
            self.UpdateOrganisationEmailDTO.email_username;
          self.organisation.email_password =
            self.UpdateOrganisationEmailDTO.email_password;
          self.organisation.email_security =
            self.UpdateOrganisationEmailDTO.email_security;
          self.organisation.email_mode =
            self.UpdateOrganisationEmailDTO.email_mode;
          self.organisation.email_graph_tenant =
            self.UpdateOrganisationEmailDTO.email_graph_tenant;
          self.organisation.email_graph_client_id =
            self.UpdateOrganisationEmailDTO.email_graph_client_id;
          self.organisation.email_graph_client_secret =
            self.UpdateOrganisationEmailDTO.email_graph_client_secret;
          self.$emit("updatedInformation", self.organisation);
          self.loading = false;
        })
        .catch(function() {
          self.loading = false;
        });
    },
    testBtn: function() {
      let self = this;
      self.loading = true;
      self.$http
        .get(
          self.user.hostname +
            "/organisation/email/test/" +
            self.organisation.id
        )
        .then(function() {
          self.$noty.info(self.$t("SENT"));
          self.loading = false;
        })
        .catch(function() {
          self.loading = false;
        });
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    IsUpdated(){
      return this.UpdateOrganisationEmailDTO.organisation_id == this.organisation.id && 
        this.UpdateOrganisationEmailDTO.email_provider == this.organisation.email_provider && 
        this.UpdateOrganisationEmailDTO.email_from == this.organisation.email_from && 
        this.UpdateOrganisationEmailDTO.email_from_name == this.organisation.email_from_name && 
        this.UpdateOrganisationEmailDTO.email_hostname == this.organisation.email_hostname &&
        this.UpdateOrganisationEmailDTO.email_port == this.organisation.email_port &&
        this.UpdateOrganisationEmailDTO.email_username == this.organisation.email_username &&
        this.UpdateOrganisationEmailDTO.email_password == this.organisation.email_password &&
        this.UpdateOrganisationEmailDTO.email_security == this.organisation.email_security && 
        this.UpdateOrganisationEmailDTO.email_mode == this.organisation.email_mode &&
        this.UpdateOrganisationEmailDTO.email_graph_tenant == this.organisation.email_graph_tenant &&
        this.UpdateOrganisationEmailDTO.email_graph_client_id == this.organisation.email_graph_client_id &&
        this.UpdateOrganisationEmailDTO.email_graph_client_secret == this.organisation.email_graph_client_secret;
    }
  },
  mounted: function() {},
};
</script>
<style></style>
